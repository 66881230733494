.story-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, sans-serif;
  line-height: 1.7;
  color: #333;
}

.story-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.story-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #0B4B97;
  position: relative;
}

.story-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 60px;
  height: 3px;
  background-color: #0B4B97;
}

.story-paragraph {
  font-size: 1.1rem;
  margin: 0;
}

.highlighted-text {
  font-weight: 700;
  color: #0B4B97;
}

.feature-box {
  background-color: #f8f9fa;
  border-left: 4px solid #0B4B97;
  padding: 20px 24px;
  border-radius: 4px;
  margin: 10px 0;
}

.feature-text {
  margin: 0;
  font-size: 1.1rem;
}

.conclusion {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 10px;
  color: #1e293b;
}

@media (max-width: 768px) {
  .story-container {
    padding: 30px 15px;
  }

  .story-title {
    font-size: 2rem;
  }

  .story-paragraph, .feature-text {
    font-size: 1rem;
  }
}