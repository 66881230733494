.use-cases-section {
  background-color: #f7f7f7;
  padding: 10px 20px;
  width: 86%;  /* Use 80% width */
  max-width: 1250px;  /* Set a max width to prevent it from becoming too wide on large screens */
  margin: 0 auto; /* Center the section horizontally */
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  margin-bottom: 60px;
}

.section-title {
  font-size: 2.5rem;
  color: #0B4B97;
  margin-bottom: 40px;
}

.use-cases-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  justify-items: center;
}

.use-case-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 240px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.use-case-card:hover {
  transform: translateY(-10px);
}

.use-case-icon {
  font-size: 2.5rem;
  color: #0B4B97;
  margin-bottom: 15px;
}

.use-case-title {
  font-size: 1.4rem;
  color: #333333;
  margin-bottom: 12px;
}

.use-case-description {
  font-size: 0.95rem;
  color: #666666;
  line-height: 1.5;
}
